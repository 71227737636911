<template>
  <div style="background: #fff">
    <!-- 引入微信sdk -->
    <!-- <remote-js></remote-js> -->
    <!-- 引入微信sdk -->
    <div class="free-header">
      <img
        src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/extendInfo/overseas.png"
        width="100%"
        height="auto"
      />
    </div>
    <van-form @submit="onSubmit" label-width="120px">
      <div class="overseas">
        <div class="free-title">
          <div class="one">代看房信息</div>
          <div class="two">(*为必填)</div>
        </div>
      </div>
      <div class="height"></div>
      <div v-for="(item, index) in serviceList" :key="index">
        <div :class="index > 0 ? 'overseas-bottom' : ''"></div>
        <div class="overseas over-flex">
          <div class="free-title">
            <div class="overseas-one">代看房房源信息</div>
            <div class="overseas-two">({{ index + 1 }})</div>
          </div>
          <van-icon
            v-if="index > 0"
            name="delete"
            size="20"
            @click="deleteClick(index)"
          />
        </div>
        <van-cell-group>
          <van-field
            :disabled="item.disabled"
            v-model="item.address"
            required
            :rules="[{ required: true }]"
            label="委托看房地址"
            placeholder="请输入委托看房地址"
          />
          <!-- 委托看房时间*		 -->
          <van-field
            :disabled="item.disabled"
            :value="item.watchTime"
            readonly
            required
            :rules="[{ required: true }]"
            label="委托看房时间"
            placeholder="请选择委托看房时间*"
            @click="item.isShow = !disabled"
            right-icon="notes-o"
          />
          <van-popup v-model="item.isShow" position="bottom">
            <van-datetime-picker
              type="datetime"
              :disabled="item.disabled"
              title="选择年月日"
              v-model="currentDate"
              @confirm="confirm(index, $event)"
              @cancel="item.isShow = false"
            />
          </van-popup>
          <van-field
            :disabled="item.disabled"
            v-model="item.contactName"
            required
            :rules="[{ required: true }]"
            label="看房联系人姓名"
            placeholder="请输入看房联系人姓名"
          />
          <van-field
            :disabled="item.disabled"
            v-model="item.contactPhone"
            type="tel"
            required
            :rules="[{ required: true }]"
            label="看房联系人电话"
            placeholder="请输入看房联系人电话"
          />
          <van-field
            :disabled="item.disabled"
            v-model="item.contactEmail"
            type="text"
            required
            :rules="[
              { required: true },
              {
                validator,
                message: '请输入正确的邮箱格式',
              },
            ]"
            label="看房联系人邮箱"
            placeholder="请输入看房联系人邮箱"
          />
        </van-cell-group>
        <van-dialog
          v-model="item.openShow"
          showCancelButton
          title="温馨提示"
          cancelButtonColor="#8AD236"
          confirmButtonColor="#F67F31"
          @cancel="cancelClick(index)"
          @confirm="confirmClick(index)"
        >
          <div class="del-infor">是否删除此条代看房房源信息？</div>
        </van-dialog>
      </div>

      <!-- 新增看房信息按钮 -->
      <div class="look-btn" v-if="serviceList.length < serviceNum">
        <div class="look-button" @click="addClick">
          <van-icon class="icon-add" name="add-square" size="20" />
          新增代看房信息
        </div>
      </div>

      <div class="message">
        如遇到问题请至邮件info@clozhome或您的顾问获取帮助
      </div>
      <div class="spacing-height"></div>
      <div class="bottom-flex" v-if="!disabled">
        <div class="flex-left">
          <div class="left-consult" @click="serviceClick">
            <van-image
              width="23"
              height="20"
              fit="scale-down"
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/consult.png"
            />
            <br />咨询客服
          </div>
        </div>
        <div class="flex-right">
          <van-button v-if="disabled" class="default" type="default"
            >立即提交</van-button
          >
          <van-button
            v-if="!disabled"
            class="primary"
            type="primary"
            native-type="submit"
            >立即提交</van-button
          >
        </div>
      </div>
    </van-form>
  </div>
</template>
<script>
import {
  addUpdateApply,
  getUpdateApplyStatus,
  getProductUpdateInfo,
} from "@/api/informations/information";
import { timeStampChangeMinute } from "@/utils/index";
// var wx = require('weixin-js-sdk');

export default {
  name: "Overseas",
  data() {
    return {
      serviceList: [
        {
          isShow: false,
          openShow: false,
          disabled: false,
        },
      ],
      product: {},
      validServiceNum: 0, //限制添加次数
      serviceNum: 0, //一共服务次数
      disabled: false,
      minDate: new Date(1910, 0, 1),
      maxDate: new Date(2080, 10, 1),
      currentDate: new Date(),
      processId: undefined,
      env: "",
    };
  },
  created() {
    // console.log("wx结果===>", wx);
    // console.log("this.wx结果===>", this.wx);
    this.processId = this.$route.query.processId;
    this.onload();
  },
  mounted() {
    let that = this;
    this.$nextTick(() => {
      document.addEventListener("UniAppJSBridgeReady", function () {
        uni.getEnv(function (res) {
          that.env = res;
          console.log("当前环境：" + JSON.stringify(res));
        });
      });
    });
  },
  methods: {
    //验证邮箱
    validator(val) {
      if (!val) return true;
      if (val.trim().length == 0) return true;
      return /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/.test(
        val
      );
    },
    //验证邮箱...
    onload() {
      getUpdateApplyStatus({ processId: this.processId }).then((res) => {
        this.serviceNum = res.data.serviceNum;
        this.validServiceNum = this.serviceNum - res.data.validServiceNum;
        if (res.data && res.data.status == 1) {
          this.serviceList = JSON.parse(res.data.updateInfo);
          this.serviceList.forEach((item, i) => {
            if (item.address) {
              item.disabled = true;
            }
            item.isShow = false;
            item.openShow = false;
          });
          if (this.validServiceNum == 0) {
            this.disabled = true;
          } else if (this.serviceList.length != this.serviceNum) {
            this.serviceList.push({
              isShow: false,
              openShow: false,
              disabled: false,
              address: undefined,
              watchTime: undefined,
              contactName: undefined,
              contactPhone: undefined,
              contactEmail: undefined,
            });
          }
        }
        this.getProductUpdateInfo(res.data.productId);
      });
    },
    getProductUpdateInfo(productId) {
      getProductUpdateInfo({ productId: productId }).then((res) => {
        this.product = res.data;
      });
    },
    confirm(index, data) {
      console.log("data结果===>", data);
      this.serviceList[index].watchTime = timeStampChangeMinute(data);
      // this.serviceList[index].watchTime = data
      this.serviceList[index].isShow = false;
      this.serviceList[index].openShow = false;
    },
    serviceClick() {
      console.log("点击联系客服===>");
      // if (this.env.miniprogram) {
      //   uni.navigateTo({
      //     url: "/otherPages/webview/showContact",
      //   });
      // } else {
      //   enterMeiQiaService.postMessage(
      //     JSON.stringify({
      //       serviceName: this.product.productName,
      //       applyType: this.product.updateApplyName,
      //     })
      //   );
      // }
      try {
        enterMeiQiaService.postMessage(
          JSON.stringify({
            serviceName: this.product.productName,
            applyType: this.product.updateApplyName,
          })
        );
      } catch (error) {
        console.log("error结果===>", error);
        uni.navigateTo({
          url: "/otherPages/webview/showContact",
        });
      }
    },
    //新增代看房信息
    addClick() {
      if (this.validServiceNum == 0) {
        this.$toast({
          message: "服务次数已用完 !",
        });
        return;
      }
      if (this.serviceList.length != this.serviceNum) {
        this.serviceList.push({
          isShow: false,
          openShow: false,
          disabled: false,
          address: undefined,
          watchTime: undefined,
          contactName: undefined,
          contactPhone: undefined,
          contactEmail: undefined,
        });
      }
    },
    //删除代看房信息
    deleteClick(index) {
      this.serviceList[index].openShow = true;
    },
    confirmClick(index) {
      console.log(index);
      this.serviceList.splice(index, 1);
    },
    cancelClick(index) {
      this.serviceList[index].openShow = false;
    },

    // 提交接口
    onSubmit() {
      var that = this;
      if (this.disabled) {
        return;
      }
      this.serviceList.forEach((item, i) => {
        delete item.isShow;
        delete item.openShow;
        delete item.disabled;
      });
      let obj = {
        updateApplyJson: JSON.stringify(this.serviceList),
        processId: this.processId,
      };
      addUpdateApply(obj).then((res) => {
        console.log("提交补充表单结果===>", res);
        // if (res.code == 0) {
        var obj2 = JSON.stringify({
          orderNo: res.data.orderNo,
          orderTime: res.data.orderTime,
          recordId: res.data.recordId,
        });
        var obj3 = encodeURIComponent(obj2);
        // if (that.env.miniprogram) {
        //   console.log("that.env.miniprogram结果===>", that.env.miniprogram);
        //   uni.navigateTo({
        //     url: `/applyPages/extendInfo?orders=${obj3}`,
        //   });
        // } else {
        //   enterApplyResult.postMessage(
        //     JSON.stringify({
        //       orderNo: res.data.orderNo,
        //       orderTime: res.data.orderTime,
        //       recordId: res.data.recordId,
        //     })
        //   );
        // }
        try {
          enterApplyResult.postMessage(
            JSON.stringify({
              orderNo: res.data.orderNo,
              orderTime: res.data.orderTime,
              recordId: res.data.recordId,
            })
          );
        } catch (error) {
          console.log("that.env.miniprogram结果===>", that.env.miniprogram);
          uni.navigateTo({
            url: `/applyPages/extendInfo?orders=${obj3}`,
          });
        }
        // }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bottom-flex {
  padding-top: 10px;
  padding-bottom: 10px;
}

.overseas {
  padding: 15px 17px;
}

.over-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overseas-bottom {
  height: 10px;
  width: 100%;
  background: #f8f8f8;
}

.flex-right {
  border-radius: 4px;
  overflow: hidden;
}

.height {
  width: 100%;
  height: 2px;
  background: #f8f8f8;
}

.look-btn {
  background: #f8f8f8;
  padding: 10px 10px;
}

.look-button {
  height: 37px;
  font-size: 14px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-add {
  margin-right: 10px;
}

.del-infor {
  text-align: center;
  line-height: 60px;
}

/deep/.van-icon-notes-o::before {
  font-size: 22px;
}

/deep/.van-button {
  height: 40px;
  width: 100%;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
}
</style>
