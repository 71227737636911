import request from '@/utils/request'
/**
 * 提交申请
 * @param query
 */
export function addUpdateApply(data) {
	return request({
		url: '/order/apply/addUpdateApply',
		method: 'post',
		data: data
	})
}
//获取补充申请填写状态及内容
export function getUpdateApplyStatus(query) {
	return request({
		url: '/order/apply/getUpdateApplyStatus',
		method: 'get',
		params: query
	})
}
//根据产品id获取产品补充申请名称
export function getProductUpdateInfo(query) {
	return request({
		url: '/order/product/getProductUpdateInfo',
		method: 'get',
		params: query
	})
}

